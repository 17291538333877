var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "extended-forms" }, [
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-4" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v("Datetime Picker"),
            ]),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "datetime",
                    placeholder: "Select date and time",
                    "picker-options": _vm.pickerOptions1,
                  },
                  model: {
                    value: _vm.dateTimePicker,
                    callback: function ($$v) {
                      _vm.dateTimePicker = $$v
                    },
                    expression: "dateTimePicker",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "col-md-4" }, [
            _c("h4", { staticClass: "card-title" }, [_vm._v("Date Picker")]),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "date",
                    placeholder: "Pick a day",
                    "picker-options": _vm.pickerOptions1,
                  },
                  model: {
                    value: _vm.datePicker,
                    callback: function ($$v) {
                      _vm.datePicker = $$v
                    },
                    expression: "datePicker",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "col-md-4" }, [
            _c("h4", { staticClass: "card-title" }, [_vm._v("Time Picker")]),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("el-time-select", {
                  attrs: {
                    "picker-options": {
                      start: "00:00",
                      step: "00:15",
                      end: "23:59",
                    },
                    placeholder: "Select time",
                  },
                  model: {
                    value: _vm.timePicker,
                    callback: function ($$v) {
                      _vm.timePicker = $$v
                    },
                    expression: "timePicker",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("br"),
        _c("br"),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c("h4", { staticClass: "card-title" }, [_vm._v("Switches")]),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("p", { staticClass: "category" }, [_vm._v("Default")]),
                _c("p-switch", {
                  attrs: {
                    type: "primary",
                    "on-text": "ON",
                    "off-text": "OFF",
                  },
                  model: {
                    value: _vm.switches.defaultOn,
                    callback: function ($$v) {
                      _vm.$set(_vm.switches, "defaultOn", $$v)
                    },
                    expression: "switches.defaultOn",
                  },
                }),
                _c("p-switch", {
                  attrs: {
                    type: "primary",
                    "on-text": "ON",
                    "off-text": "OFF",
                  },
                  model: {
                    value: _vm.switches.defaultOff,
                    callback: function ($$v) {
                      _vm.$set(_vm.switches, "defaultOff", $$v)
                    },
                    expression: "switches.defaultOff",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("p", { staticClass: "category" }, [_vm._v("Plain")]),
                _c("p-switch", {
                  model: {
                    value: _vm.switches.plainOn,
                    callback: function ($$v) {
                      _vm.$set(_vm.switches, "plainOn", $$v)
                    },
                    expression: "switches.plainOn",
                  },
                }),
                _c("p-switch", {
                  model: {
                    value: _vm.switches.plainOff,
                    callback: function ($$v) {
                      _vm.$set(_vm.switches, "plainOff", $$v)
                    },
                    expression: "switches.plainOff",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("p", { staticClass: "category" }, [_vm._v("With Icons")]),
                _c(
                  "p-switch",
                  {
                    model: {
                      value: _vm.switches.withIconsOn,
                      callback: function ($$v) {
                        _vm.$set(_vm.switches, "withIconsOn", $$v)
                      },
                      expression: "switches.withIconsOn",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-check",
                      attrs: { slot: "on" },
                      slot: "on",
                    }),
                    _c("i", {
                      staticClass: "fa fa-times",
                      attrs: { slot: "off" },
                      slot: "off",
                    }),
                  ]
                ),
                _c(
                  "p-switch",
                  {
                    model: {
                      value: _vm.switches.withIconsOff,
                      callback: function ($$v) {
                        _vm.$set(_vm.switches, "withIconsOff", $$v)
                      },
                      expression: "switches.withIconsOff",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-check",
                      attrs: { slot: "on" },
                      slot: "on",
                    }),
                    _c("i", {
                      staticClass: "fa fa-times",
                      attrs: { slot: "off" },
                      slot: "off",
                    }),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("h4", { staticClass: "card-title" }, [_vm._v("Checkboxes")]),
              _c("p-checkbox", { attrs: { checked: false } }, [
                _vm._v("Unchecked"),
              ]),
              _c("p-checkbox", { attrs: { checked: true } }, [
                _vm._v("Checked"),
              ]),
              _c("p-checkbox", { attrs: { disabled: "", checked: false } }, [
                _vm._v("Disabled unchecked"),
              ]),
              _c("p-checkbox", { attrs: { disabled: "", checked: true } }, [
                _vm._v("Disabled checked"),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-sm-3" },
            [
              _c("h4", { staticClass: "card-title" }, [_vm._v("Radio")]),
              _c(
                "p-radio",
                {
                  attrs: { label: "1" },
                  model: {
                    value: _vm.enabledRadio,
                    callback: function ($$v) {
                      _vm.enabledRadio = $$v
                    },
                    expression: "enabledRadio",
                  },
                },
                [_vm._v("Radio is off")]
              ),
              _c(
                "p-radio",
                {
                  attrs: { label: "2" },
                  model: {
                    value: _vm.enabledRadio,
                    callback: function ($$v) {
                      _vm.enabledRadio = $$v
                    },
                    expression: "enabledRadio",
                  },
                },
                [_vm._v("Radio is on")]
              ),
              _c(
                "p-radio",
                {
                  attrs: { disabled: "", label: "1" },
                  model: {
                    value: _vm.disabledRadio,
                    callback: function ($$v) {
                      _vm.disabledRadio = $$v
                    },
                    expression: "disabledRadio",
                  },
                },
                [_vm._v("Disabled radio is off")]
              ),
              _c(
                "p-radio",
                {
                  attrs: { disabled: "", label: "2" },
                  model: {
                    value: _vm.disabledRadio,
                    callback: function ($$v) {
                      _vm.disabledRadio = $$v
                    },
                    expression: "disabledRadio",
                  },
                },
                [_vm._v("Disabled radio is on")]
              ),
            ],
            1
          ),
        ]),
        _c("br"),
        _c("br"),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("h4", { staticClass: "card-title" }, [_vm._v("Tags")]),
              _vm._l(_vm.tags.dynamicTags, function (tag) {
                return _c(
                  "el-tag",
                  {
                    key: tag,
                    attrs: {
                      type: "primary",
                      closable: true,
                      "close-transition": false,
                    },
                    on: {
                      close: function ($event) {
                        return _vm.handleClose(tag)
                      },
                    },
                  },
                  [_vm._v("\n            " + _vm._s(tag) + "\n          ")]
                )
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.tags.inputValue,
                    expression: "tags.inputValue",
                  },
                ],
                ref: "saveTagInput",
                staticClass: "form-control input-new-tag",
                attrs: {
                  type: "text",
                  placeholder: "Add new tag",
                  size: "mini",
                },
                domProps: { value: _vm.tags.inputValue },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.handleInputConfirm.apply(null, arguments)
                  },
                  blur: _vm.handleInputConfirm,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.tags, "inputValue", $event.target.value)
                  },
                },
              }),
              _c("br"),
              _c("h4", { staticClass: "card-title" }, [
                _vm._v("Progress Bars"),
              ]),
              _c("p-progress", { attrs: { value: 30 } }),
              _c("br"),
              _c("p-progress", { attrs: { value: 60, type: "info" } }),
              _c("br"),
              _c("p-progress", {
                attrs: {
                  values: [
                    { type: "success", value: 35 },
                    { type: "warning", value: 20 },
                    { type: "danger", value: 10 },
                  ],
                },
              }),
              _c("br"),
              _c("h4", { staticClass: "card-title" }, [_vm._v("Sliders")]),
              _c("el-slider", {
                staticClass: "slider-success",
                model: {
                  value: _vm.sliders.simple,
                  callback: function ($$v) {
                    _vm.$set(_vm.sliders, "simple", $$v)
                  },
                  expression: "sliders.simple",
                },
              }),
              _c("br"),
              _c("el-slider", {
                staticClass: "slider-info",
                attrs: { max: 100, range: "" },
                model: {
                  value: _vm.sliders.rangeSlider,
                  callback: function ($$v) {
                    _vm.$set(_vm.sliders, "rangeSlider", $$v)
                  },
                  expression: "sliders.rangeSlider",
                },
              }),
            ],
            2
          ),
          _c("div", { staticClass: "col-md-6" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v("Customisable Select"),
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-sm-6" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-danger",
                      attrs: { size: "large", placeholder: "Single Select" },
                      model: {
                        value: _vm.selects.simple,
                        callback: function ($$v) {
                          _vm.$set(_vm.selects, "simple", $$v)
                        },
                        expression: "selects.simple",
                      },
                    },
                    _vm._l(_vm.selects.countries, function (option) {
                      return _c("el-option", {
                        key: option.label,
                        staticClass: "select-danger",
                        attrs: { value: option.value, label: option.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-sm-6" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-primary",
                      attrs: {
                        multiple: "",
                        size: "large",
                        placeholder: "Multiple Select",
                      },
                      model: {
                        value: _vm.selects.multiple,
                        callback: function ($$v) {
                          _vm.$set(_vm.selects, "multiple", $$v)
                        },
                        expression: "selects.multiple",
                      },
                    },
                    _vm._l(_vm.selects.countries, function (option) {
                      return _c("el-option", {
                        key: option.label,
                        staticClass: "select-primary",
                        attrs: { value: option.value, label: option.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-sm-6" },
                [
                  _c("br"),
                  _c("h4", { staticClass: "card-title" }, [_vm._v("Dropdown")]),
                  _c(
                    "drop-down",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function ({ isOpen }) {
                            return _c(
                              "p-button",
                              {
                                staticClass: "dropdown-toggle",
                                attrs: {
                                  type: "primary",
                                  round: "",
                                  block: "",
                                  "aria-expanded": isOpen,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  Regular\n                "
                                ),
                              ]
                            )
                          },
                        },
                      ]),
                    },
                    [
                      _c("div", { staticClass: "dropdown-header" }, [
                        _vm._v("Dropdown header"),
                      ]),
                      _c(
                        "a",
                        { staticClass: "dropdown-item", attrs: { href: "#" } },
                        [_vm._v("Action")]
                      ),
                      _c(
                        "a",
                        { staticClass: "dropdown-item", attrs: { href: "#" } },
                        [_vm._v("Another action")]
                      ),
                      _c(
                        "a",
                        { staticClass: "dropdown-item", attrs: { href: "#" } },
                        [_vm._v("Something else")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-sm-6" },
                [
                  _c("br"),
                  _c("h4", { staticClass: "card-title" }, [_vm._v("Dropup")]),
                  _c(
                    "drop-down",
                    {
                      attrs: { direction: "up" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function ({ isOpen }) {
                            return _c(
                              "p-button",
                              {
                                staticClass: "dropdown-toggle",
                                attrs: {
                                  type: "info",
                                  round: "",
                                  block: "",
                                  "aria-expanded": isOpen,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  Regular\n                "
                                ),
                              ]
                            )
                          },
                        },
                      ]),
                    },
                    [
                      _c("div", { staticClass: "dropdown-header" }, [
                        _vm._v("Dropdown header"),
                      ]),
                      _c(
                        "a",
                        { staticClass: "dropdown-item", attrs: { href: "#" } },
                        [_vm._v("Action")]
                      ),
                      _c(
                        "a",
                        { staticClass: "dropdown-item", attrs: { href: "#" } },
                        [_vm._v("Another action")]
                      ),
                      _c(
                        "a",
                        { staticClass: "dropdown-item", attrs: { href: "#" } },
                        [_vm._v("Something else")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _c("br"),
        _c("br"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }